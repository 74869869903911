import React from 'react';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement
} from '@stripe/react-stripe-js';
import './stripePayment.css';
import useResponsiveFontSize from '../../../hooks/useResponsiveFontSize';
import { FullPageSpinner } from '../../../components/spinner';
import ModalError from '../../../components/modal/ModalError';
import { usePaymentProvider } from '../../../context/PaymentProvider';
import { handleAndroidPaymentStatus } from '../../verifyPayment';
import CardsImages from './CardsImages';
import {
  confirmPayment,
  makeIntent,
  makeIntentPayload,
  PaidForEntity
} from '../../../api/payment';
import PayButton from '../payButton';

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = React.useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: '#424770',
          letterSpacing: '0.025em',
          fontFamily: 'Source Code Pro, monospace',
          '::placeholder': {
            color: '#aab7c4'
          }
        },
        invalid: {
          color: '#ff0000'
        }
      }
    }),
    [fontSize]
  );

  return options;
};

const cardFields = [
  {
    label: 'Card number',
    fieldName: 'cardNumber',
    component: CardNumberElement
  },
  {
    label: 'Expiration data',
    fieldName: 'cardExpiry',
    component: CardExpiryElement
  },
  {
    label: 'CVC',
    fieldName: 'cardCvc',
    component: CardCvcElement
  }
];

const StripeForm = ({ urlParameters, setSuccess }: any) => {
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const [cardFieldErrors, setCardFieldErrors] = React.useState<any>({
    cardNumber: null,
    cardExpiry: null,
    cardCvc: null
  });
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<null | any>(null);
  const { selectedProvider }: any = usePaymentProvider();

  function onChangeCardInput(e: any) {
    setCardFieldErrors((prevState: any) => ({
      ...prevState,
      [e.elementType]: e.error
    }));
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    try {
      setError(null);
      setLoading(true);
      const { paymentMethod, error }: any = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardNumberElement) as any
      });
      if (error) {
        setLoading(false);
        return;
      }
      const paidForEntity = urlParameters.bId
        ? PaidForEntity.BOOKING
        : PaidForEntity.CONSULTATION;

      const data = await makeIntent(
        makeIntentPayload(selectedProvider, urlParameters, paymentMethod.id)
      );

      await confirmPayment(
        `?stripeIntentId=${data.stripeIntentId}&provider=${selectedProvider}&trxId=${data.trxId}&paidFor=${paidForEntity}&serviceProviderIds=${urlParameters.serviceProviderIds}`
      );
      handleAndroidPaymentStatus(true);
      setLoading(false);
      setSuccess(true);
    } catch (error) {
      handleAndroidPaymentStatus(false);
      setLoading(false);
      setError({ message: error.message });
    }
  };

  return (
    <div className="stripePayment">
      {loading && <FullPageSpinner />}
      <ModalError
        show={error}
        message="Please try again in a moment."
        setShow={() => setError(null)}
      />
      <div className="flex flex-wrap mt-6 mb-3">
        <CardsImages />
      </div>
      <div className="pb-12 md:pb-0">
        <form
          onSubmit={handleSubmit}
          className="bg-white shadow border border-gray-200 px-2 lg:px-12 py-4 lg:py-8 rounded-md"
        >
          {cardFields.map(({ label, fieldName, component: Component }) => (
            <label key={fieldName} className="text-sm">
              {label}
              <Component options={options} onChange={onChangeCardInput} />
              {cardFieldErrors[fieldName] && (
                <p className="text-error mb-2">
                  {cardFieldErrors[fieldName].message}
                </p>
              )}
            </label>
          ))}
          <PayButton type="submit" disabled={!stripe || loading} />
        </form>
      </div>
    </div>
  );
};

export default StripeForm;
