import { paymentProviderType } from '../context/PaymentProvider';
import { apiPost } from './method';

enum ProductEntity {
  SERVICE = 'service',
  ON_DEMAND_PRODUCT = 'onDemandProduct'
}

export enum PaidForEntity {
  BOOKING = 'booking',
  CONSULTATION = 'consultation'
}

interface IntentPayload {
  provider: paymentProviderType;
  productId: string;
  productEntity: ProductEntity.SERVICE | ProductEntity.ON_DEMAND_PRODUCT;
  paidForEntityId?: string;
  paidForEntity: PaidForEntity.BOOKING | PaidForEntity.CONSULTATION;
  serviceProviderId: string;
  bookableResourceId?: string;
  clientId?: string;
  clientUserId?: string;
  stripePaymentId?: null | string;
}

export function makeIntentPayload(
  selectedProvider,
  urlParameters,
  stripePaymentId = null
): IntentPayload {
  if (urlParameters.pType === ProductEntity.ON_DEMAND_PRODUCT) {
    let serviceProviderId = urlParameters.serviceProviderIds;
    serviceProviderId = JSON.parse(serviceProviderId);
    serviceProviderId =
      Array.isArray(serviceProviderId) && serviceProviderId.length
        ? serviceProviderId[0]
        : null;
    return {
      provider: selectedProvider,
      productEntity: ProductEntity.ON_DEMAND_PRODUCT,
      productId: urlParameters.pId,
      clientId: urlParameters.clientId,
      clientUserId: urlParameters.clientUserId,
      serviceProviderId,
      paidForEntity: PaidForEntity.CONSULTATION,
      stripePaymentId
    };
  }

  const hasBookingId = Boolean(urlParameters.bId);

  if (urlParameters.pType === ProductEntity.SERVICE && hasBookingId) {
    return {
      provider: selectedProvider,
      productId: urlParameters.pId,
      productEntity: urlParameters.pType,
      paidForEntityId: urlParameters.bId,
      paidForEntity: PaidForEntity.BOOKING,
      serviceProviderId: urlParameters.spId,
      bookableResourceId: urlParameters.brId,
      clientId: urlParameters.clientId,
      clientUserId: urlParameters.clientUserId,
      stripePaymentId
    };
  }

  if (urlParameters.pType === ProductEntity.SERVICE && !hasBookingId) {
    return {
      provider: selectedProvider,
      productId: urlParameters.pId,
      productEntity: urlParameters.pType,
      paidForEntityId: urlParameters.bId,
      paidForEntity: PaidForEntity.BOOKING,
      serviceProviderId: urlParameters.spId,
      bookableResourceId: urlParameters.brId,
      clientId: urlParameters.clientId,
      clientUserId: urlParameters.clientUserId,
      stripePaymentId
    };
  }

  throw new Error('Invalid payment criteria');
}

async function makeIntent(payload: IntentPayload): Promise<any> {
  const { data } = await apiPost('/payments/intent', payload);
  return data;
}

async function confirmPayment(
  urlParams: string,
  payload: any = {}
): Promise<any> {
  return apiPost(`/payments/verifyTransaction${urlParams}`, payload);
}

export { makeIntent, confirmPayment };
