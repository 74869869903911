import React from 'react';
import visaCard from '../../../assets/images/cards/visa.png';
import masterCard from '../../../assets/images/cards/master.png';
import amexCard from '../../../assets/images/cards/amex.png';
// import jcbCard from '../../../assets/images/cards/jcb.png';

const cardImageSrcs = {
  visaCard,
  masterCard,
  amexCard
  // jcbCard
};

function CardsImages() {
  return (
    <div className="flex flex-wrap">
      {Object.values(cardImageSrcs).map((imgSrc, index) => (
        <img
          className="w-16"
          style={{ marginRight: '0.125rem', marginBottom: '0.125rem' }}
          key={`card-${index}`}
          src={imgSrc}
          alt=""
        />
      ))}
    </div>
  );
}

export default CardsImages;
