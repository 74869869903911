import React, { Component } from 'react';

interface Props {
  children: React.ReactNode;
}

interface State {
  hasError: Boolean;
  hasRetried: Boolean;
}

class ErrorBoundary extends Component<Props, State> {
  state = { hasError: false, hasRetried: false };

  static getDerivedStateFromError = () => ({ hasError: true });

  componentDidCatch = () => {
    if (this.state.hasRetried) {
      window.location.reload();
      return;
    }
    this.setState({ hasError: true });
  };

  resetState = () => {
    this.setState({ hasError: false, hasRetried: true });
  };
  render() {
    if (this.state.hasError) {
      return (
        <div className="min-h-screen w-full flex flex-col justify-center items-center">
          <h1 className="text-center font-medium text-lg">
            Oops! something went wrong.
          </h1>
          <button
            className="w-32 text-white bg-primary rounded px-4 py-2 mt-10 hover:bg-opacity-75 focus:outline-none"
            onClick={this.resetState}
          >
            Try again
          </button>
        </div>
      );
    }

    return this.props.children || null;
  }
}

export default ErrorBoundary;
