import React from 'react';

import logoImg from '../../assets/images/eokhati_full_green.png';

const Header: React.FC = () => {
  return (
    <header className="container mx-auto mt-16 mb-10">
      <img
        className="w-auto h-8"
        src={logoImg}
        alt="okhati-logo"
        style={{ imageRendering: '-webkit-optimize-contrast' }}
      ></img>
    </header>
  );
};

export default Header;
