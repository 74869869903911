import React from 'react';

const NoPaymentMessage: React.FC = () => {
  return (
    <div className="container mx-auto mb-16">
      <div className="mt-20 text-center font-medium text-lg">
        You do not have any payment at the moment.
      </div>
    </div>
  );
};

export default NoPaymentMessage;
