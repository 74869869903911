import React from 'react';
import { useLocation, RouteComponentProps } from '@reach/router';
import { parse } from 'query-string';
import moment from 'moment';
import startCase from 'lodash/startCase';
import NoPaymentMessage from './noPaymentMessage';
import KhaltiPayment from './khalti';
import StripePayment from './stripe/StripePayment';
import CommonPayment from './common/CommonPayment';
import {
  paymentProviderType,
  usePaymentProvider
} from '../../context/PaymentProvider';
import { makeDisplayAmount } from '../../utils/currency';
import PaymentOptions from './common/PaymentOptions';

const MakePayment: React.FC<RouteComponentProps> = () => {
  const location = useLocation();
  const { country, selectedProvider } = usePaymentProvider();

  const urlParameters: { [key: string]: any } = parse(location.search);

  React.useEffect(() => {
    if (!urlParameters.q && location.search) {
      localStorage.setItem('persist-trx-url', location.href);
      localStorage.setItem('persist-redirect-url', urlParameters.rd);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!location.search) {
    return <NoPaymentMessage />;
  }
  const isForeignPayment = selectedProvider === paymentProviderType.STRIPE;
  const displayAmount = makeDisplayAmount(urlParameters.price, country);

  function renderProductDetails() {
    const isBookableResource = urlParameters.brId && urlParameters.brName;
    if (isBookableResource) {
      const displayName = urlParameters.brName
        .split('_')
        .reduce(
          (fullName: string, name: string) =>
            `${startCase(fullName)} ${startCase(name)}`
        );
      return (
        <div className="mr-4">
          <div className="text-lg md:text-xl font-medium">{displayName}</div>
          <div className="text-sm font-medium">
            on {moment(urlParameters.t).format('LLLL')}
          </div>
        </div>
      );
    }

    return (
      <div className="mr-4">
        <div className="text-lg md:text-xl font-medium">
          {startCase(urlParameters.pName)}
        </div>
        <div className="text-sm font-medium">
          on {moment(urlParameters.t).format('LLLL')}
        </div>
        <div className="text-sm font-medium">
          with {startCase(urlParameters.spT)}.{' '}
          {startCase(urlParameters.spFName)} {startCase(urlParameters.spLName)}
        </div>
      </div>
    );
  }

  function renderPaymentMethod() {
    if (isForeignPayment) {
      return <StripePayment urlParameters={urlParameters} />;
    } else if (selectedProvider === paymentProviderType.KHALTI) {
      return <KhaltiPayment urlParameters={urlParameters} />;
    } else if (selectedProvider === paymentProviderType.ESEWA) {
      return <CommonPayment urlParameters={urlParameters} />;
    } else {
      return null;
    }
  }

  return (
    <div className="container mx-auto mb-16 transition opacity ease-in duration-1000">
      <div className="bg-white shadow border border-gray-200 px-2 lg:px-12 py-4 lg:py-8 flex flex-wrap justify-between items-start rounded-md">
        {renderProductDetails()}
        <div className="text-lg md:text-xl font-medium">{displayAmount}</div>
      </div>
      <div className="bg-white px-2 lg:px-12 py-4 flex flex-wrap justify-end">
        <div className="text-lg md:text-xl font-bold">Total:</div>
        <div className="text-lg md:text-xl font-bold ml-4">{displayAmount}</div>
      </div>
      <h2 className="text-lg font-semibold mt-10 mb-3">Payment method:</h2>
      {!isForeignPayment && <PaymentOptions />}
      {renderPaymentMethod()}
    </div>
  );
};

export default MakePayment;
