import React from 'react';
import { Router } from '@reach/router';
import ErrorBoundary from './components/ErrorBoundary';
import Header from './components/header';
import MakePayment from './pages/makePayment';
import VerifyPayment from './pages/verifyPayment';
import NotFound from './pages/Notfound';
import { PaymentProvider } from './context/PaymentProvider';

function App() {
  return (
    <ErrorBoundary>
      <Header />
      <PaymentProvider>
        <Router>
          <MakePayment path="/" />
          <VerifyPayment path="/verify" />
          <NotFound default />
        </Router>
      </PaymentProvider>
    </ErrorBoundary>
  );
}

export default App;
