import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import StripeForm from './StripeForm';
import SuccessMessage from '../../verifyPayment/successMessage';
import './stripePayment.css';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK as any);

function StripePayment({ urlParameters }: any) {
  const [success, setSuccess] = React.useState(false);

  React.useEffect(() => {
    let timerRef: any;
    if (success) {
      timerRef = setTimeout(() => {
        redirectToOrigin();
      }, 5000);
    }

    return () => timerRef && clearTimeout(timerRef);
  }, [success]);

  const redirectToOrigin = () => {
    const redirectPersistUrl = localStorage.getItem('persist-redirect-url');
    localStorage.clear();
    window.location.href = `${redirectPersistUrl}`;
  };
  if (success) {
    return <SuccessMessage timer={5000} redirectToOrigin={redirectToOrigin} />;
  }
  return (
    <Elements stripe={stripePromise}>
      <StripeForm urlParameters={urlParameters} setSuccess={setSuccess} />
    </Elements>
  );
}

export default StripePayment;
