import * as React from 'react';
import { RouteComponentProps } from '@reach/router';

const NotFound: React.FC<RouteComponentProps> = () => {
  return (
    <div className="container mx-auto">Oops! the page does not exist.</div>
  );
};

export default NotFound;
