import axios from 'axios';

const options = {
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
};

export const apiGet = (url: String) => {
  return axios.get(`${process.env.REACT_APP_SERVER_URL}${url}`);
};

export const apiPost = (url: string, data = {}) => {
  return axios.post(`${process.env.REACT_APP_SERVER_URL}${url}`, data, options);
};
