import React from 'react';

import './index.scss';

export const Spinner = ({ size = 2, lineWidth = 4 }) => {
  return (
    <div
      style={{
        width: `${size}rem`,
        height: `${size}rem`,
        borderWidth: `${lineWidth}px`
      }}
      className="inline-block border-primary rounded-full spinner"
    />
  );
};

export const FullPageSpinner = ({ size = 4, lineWidth = 8 }) => {
  return (
    <div className="w-full h-full fixed flex flex items-center justify-center top-0 left-0 z-50">
      <Spinner size={size} lineWidth={lineWidth} />
    </div>
  );
};
