import React from 'react';
import { useLocation, RouteComponentProps } from '@reach/router';
import { parse } from 'query-string';
import { FullPageSpinner } from '../../components/spinner';
import SuccessMessage from './successMessage';
import { usePaymentProvider } from '../../context/PaymentProvider';
import { confirmPayment, PaidForEntity } from '../../api/payment';

declare global {
  interface Window {
    ReactNativeWebView: any;
    Android: any;
  }
}

export function handleAndroidPaymentStatus(status: boolean) {
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.onPayment(status);
  }
  if (window.Android) {
    window.Android.onPayment(status);
  }
}

const redirectTimeToOrigin = 5000;

const VerifyPayment: React.FC<RouteComponentProps> = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const { selectedProvider }: any = usePaymentProvider();

  const location = useLocation();
  const { q, oid, MERCHANTTXNID, refId, GTWREFNO, amt }: any = parse(
    location.search
  );

  const redirectToMakePayment = (status?: string) => {
    const statusParams = status ? `&q=${status}` : '';
    window.location.href = `${localStorage.getItem(
      'persist-trx-url'
    )}${statusParams}`;
  };

  const redirectToOrigin = () => {
    handleAndroidPaymentStatus(true);
    const redirectPersistUrl = localStorage.getItem('persist-redirect-url');
    localStorage.clear();
    window.location.href = `${redirectPersistUrl}`;
  };

  React.useEffect(() => {
    if (!location.search) redirectToMakePayment();
    if (!q) return;
    if (q === 'failure') {
      handleAndroidPaymentStatus(false);
      redirectToMakePayment(q);
      return;
    }
    setIsLoading(true);
    let timer: any = null;

    async function verifyPayment() {
      try {
        const trxURL = localStorage.getItem('persist-trx-url');
        let paidFor = PaidForEntity.BOOKING;
        let serviceProviderIds = null;
        if (trxURL) {
          const paidForConsultation = parse(trxURL).cId || null;
          const serviceProviderIdsParsed = parse(trxURL).serviceProviderIds;
          if (typeof paidForConsultation === 'string' && paidForConsultation) {
            paidFor = PaidForEntity.CONSULTATION;
            serviceProviderIds = serviceProviderIdsParsed;
          }
        }
        await confirmPayment(
          `?oid=${oid}&MERCHANTTXNID=${MERCHANTTXNID}&refId=${refId}&GTWREFNO=${GTWREFNO}&amt=${amt}&provider=${selectedProvider}&paidFor=${paidFor}&serviceProviderIds=${serviceProviderIds}`
        );
        handleAndroidPaymentStatus(true);
        setIsLoading(false);
        timer = setTimeout(() => {
          redirectToOrigin();
        }, redirectTimeToOrigin);
      } catch (error) {
        redirectToMakePayment('failure');
      }
    }
    verifyPayment();

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [q]);

  return (
    <>
      {isLoading ? (
        <FullPageSpinner />
      ) : (
        <SuccessMessage
          timer={redirectTimeToOrigin}
          redirectToOrigin={redirectToOrigin}
        />
      )}
    </>
  );
};

export default VerifyPayment;
