import React from 'react';

const FormData = React.forwardRef((props: any, ref: any) => (
  <form className="hidden" ref={ref} action={props.url} method="post">
    {Object.keys(props.data).map((key) => (
      <input key={key} name={key} value={props.data[key]} type="hidden" />
    ))}
    <input type="submit" value="Submit" />
  </form>
));

export default FormData;
