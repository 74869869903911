import React from 'react';
import RadioBox from './radioBox';
import { usePaymentProvider } from '../../../context/PaymentProvider';

function PaymentOptions() {
  const {
    providers,
    selectedProvider,
    setSelectedProvider
  }: any = usePaymentProvider();

  if (providers.length === 0) {
    return null;
  }

  return (
    <div className="flex items-center flex-wrap mb-5 pb-12 md:pb-0">
      <RadioBox
        items={providers.map((provider: any) => provider.provider)}
        selectedItem={selectedProvider}
        onChange={(provider: string) => {
          if (provider !== selectedProvider) {
            setSelectedProvider(provider);
          }
        }}
      />
    </div>
  );
}

export default PaymentOptions;
