import React from 'react';
import KhaltiCheckout from 'khalti-checkout-web';
import SuccessMessage from '../../verifyPayment/successMessage';
import {
  confirmPayment,
  makeIntent,
  makeIntentPayload,
  PaidForEntity
} from '../../../api/payment';
import { usePaymentProvider } from '../../../context/PaymentProvider';
import { handleAndroidPaymentStatus } from '../../verifyPayment';
import PayButton from '../payButton';
import { FullPageSpinner } from '../../../components/spinner';
import ModalError from '../../../components/modal/ModalError';

function KhaltiPayment({ urlParameters }: any) {
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const { selectedProvider }: any = usePaymentProvider();

  async function onSuccess(data) {
    try {
      const { product_identity, token } = data;
      setLoading(true);
      setError(false);
      const paidForEntity = urlParameters.bId
        ? PaidForEntity.BOOKING
        : PaidForEntity.CONSULTATION;
      const serviceProviderIds =
        paidForEntity === PaidForEntity.CONSULTATION
          ? urlParameters.serviceProviderIds
          : null;
      await confirmPayment(
        `?productIdentity=${product_identity}&refId=${token}&provider=${selectedProvider}&paidFor=${paidForEntity}&serviceProviderIds=${serviceProviderIds}`
      );

      handleAndroidPaymentStatus(true);
      setLoading(false);
      setSuccess(true);
    } catch (error) {
      handleAndroidPaymentStatus(false);
      console.log('Verification error:', error);
      setLoading(false);
      setError(true);
    }
  }

  function onError(error) {
    console.log('Khalti error:', error);
    setError(true);
  }

  async function onHandlePay() {
    try {
      setError(false);
      setLoading(true);
      const { amount, productIdentity } = await makeIntent(
        makeIntentPayload(selectedProvider, urlParameters)
      );

      const paymentConfig = {
        publicKey: process.env.REACT_APP_KHALTI_PK,
        productIdentity,
        productName: urlParameters.bId
          ? PaidForEntity.BOOKING
          : PaidForEntity.CONSULTATION,
        productUrl: 'https://okhati.com.np',
        eventHandler: {
          onSuccess,
          onError,
          onClose() {}
        },
        paymentPreference: [
          'KHALTI',
          'EBANKING',
          'MOBILE_BANKING',
          'CONNECT_IPS',
          'SCT'
        ]
      };

      const checkout = new KhaltiCheckout(paymentConfig);
      checkout.show({ amount });
      setLoading(false);
    } catch (error) {
      handleAndroidPaymentStatus(false);
      console.log('Intent error:', error);
      setLoading(false);
      setError(true);
    }
  }

  React.useEffect(() => {
    let timerRef: any;
    if (success) {
      timerRef = setTimeout(() => {
        redirectToOrigin();
      }, 5000);
    }

    return () => timerRef && clearTimeout(timerRef);
  }, [success]);

  const redirectToOrigin = () => {
    const redirectPersistUrl = localStorage.getItem('persist-redirect-url');
    localStorage.clear();
    window.location.href = `${redirectPersistUrl}`;
  };

  if (success) {
    return <SuccessMessage timer={5000} redirectToOrigin={redirectToOrigin} />;
  }

  return (
    <>
      {loading && <FullPageSpinner />}
      <ModalError show={error} setShow={() => setError(!onError)} />
      <PayButton disabled={loading} onHandlePay={onHandlePay} />
    </>
  );
}

export default KhaltiPayment;
