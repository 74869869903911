import { countryCodeType } from '../context/PaymentProvider';

const currencyUnits: { [key: string]: string } = {
  npr: 'Rs.',
  usd: '$'
};

export const makeDisplayAmount = (
  currencyValue: string | number,
  currencyCode: string
) => {
  if (!currencyValue || !currencyCode) {
    return null;
  }
  // defaults currencyUnit to usd if abroad
  const currencyUnit =
    currencyCode === countryCodeType.NP ? currencyUnits.npr : currencyUnits.usd;

  try {
    const displayAmount = `${currencyUnit} ${currencyValue}`;
    return displayAmount;
  } catch (error) {
    throw new Error(`currencyCode or currencyValue Error: ${error}`);
  }
};
