import React from 'react';
import { navigate } from '@reach/router';
import { FullPageSpinner } from '../../../components/spinner';
import FormData from './formData';
import {
  paymentProviderType,
  usePaymentProvider
} from '../../../context/PaymentProvider';
import ModalError from '../../../components/modal/ModalError';
import { makeIntent, makeIntentPayload } from '../../../api/payment';
import PayButton from '../payButton';

const CommonPayment: React.FC<{ urlParameters }> = ({ urlParameters }) => {
  const { providers, selectedProvider } = usePaymentProvider();
  const [isLoading, setIsLoading] = React.useState(false);
  const [onError, setOnError] = React.useState(false);

  const [formData, setFormData] = React.useState<any>(null);
  const formRef = React.useRef<any>();

  React.useEffect(() => {
    if (formRef.current && formData) {
      formRef.current.submit();
    }
  }, [formData]);

  const hasUrlParameters = Object.keys(urlParameters).length > 0;

  React.useEffect(() => {
    if (!hasUrlParameters && localStorage.getItem('persist-trx-url')) {
      navigate(`${localStorage.getItem('persist-trx-url')}`, {
        replace: true
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (urlParameters.q === 'failure') {
      setOnError(true);
      navigate(`${localStorage.getItem('persist-trx-url')}`, {
        replace: true
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlParameters.q]);

  const onHandlePay = async () => {
    try {
      setOnError(false);
      setIsLoading(true);

      const data: any = await makeIntent(
        makeIntentPayload(selectedProvider, urlParameters)
      );
      if (data.hasOwnProperty('cId')) {
        const trlURL = localStorage.getItem('persist-trx-url');
        if (trlURL && typeof trlURL === 'string') {
          const updatedURL = trlURL + `&cId=${data.cId}`;
          localStorage.setItem('persist-trx-url', updatedURL);
          delete data['cId'];
        }
      }
      setFormData(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setOnError(true);
    }
  };

  const showPayButton =
    providers.length && selectedProvider === paymentProviderType.ESEWA;

  return (
    <div className="mx-auto mb-16">
      {isLoading && <FullPageSpinner />}
      <ModalError show={onError} setShow={() => setOnError(!onError)} />
      {formData && (
        <FormData ref={formRef} url={formData.postUrl} data={formData.data} />
      )}
      {showPayButton && <PayButton onHandlePay={onHandlePay} />}
    </div>
  );
};

export default CommonPayment;
