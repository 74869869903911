import React from 'react';
import asyncHelper from '.././api/helper';
import { apiGet } from '.././api/method';
import { FullPageSpinner } from '../components/spinner';

export enum paymentProviderType {
  ESEWA = 'eSewa',
  STRIPE = 'stripe',
  KHALTI = 'khalti'
}

export type PaymentProvider =
  | paymentProviderType.ESEWA
  | paymentProviderType.STRIPE
  | paymentProviderType.KHALTI;

export enum countryCodeType {
  NP = 'NP'
}

export type PaymentProviderContextType = {
  country: string | null;
  providers: any[];
  selectedProvider: string | null;
  setSelectedProvider: (provider: string) => void;
};

const PaymentProviderContext = React.createContext<PaymentProviderContextType>(
  null
);

export function PaymentProvider(props: any) {
  const [country, setCountry] = React.useState<string | null>(null);
  const [providers, setProviders] = React.useState<any[]>([]);
  const [selectedProvider, setSelectedProvider] = React.useState<string | null>(
    localStorage.getItem('paymentProvider') || null
  );
  const [isLoading, setIsLoading] = React.useState(true);
  const [isError, setIsError] = React.useState(false);

  async function fetchProviders() {
    setIsError(false);
    setIsLoading(true);
    const [error, data] = await asyncHelper(apiGet('/payments/providers'));
    setIsLoading(false);
    if (error) {
      setIsError(true);
    } else {
      setProviders(
        data.data.filter(
          (provider) => provider.provider !== paymentProviderType.STRIPE
        )
      );
    }
  }

  React.useEffect(() => {
    if (selectedProvider) {
      localStorage.setItem('paymentProvider', selectedProvider);
    }
  }, [selectedProvider]);

  React.useEffect(() => {
    if (/verify/i.test(window.location.href)) {
      setIsLoading(false);
      return;
    }

    handleCountry();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleCountry() {
    const [error, data] = await asyncHelper(apiGet('/country'));

    if (error) {
      setIsError(true);
      setIsLoading(false);
      return;
    }
    setCountry(data.data.countryCode);
    if (data.data.countryCode === countryCodeType.NP) {
      initLocalPayment();
    } else {
      initForeignPayment();
    }
  }

  async function initForeignPayment() {
    setSelectedProvider(paymentProviderType.STRIPE);
    setProviders([{ provider: paymentProviderType.STRIPE }]);
    setIsLoading(false);
  }

  function initLocalPayment() {
    const localPaymentProvider = localStorage.getItem('paymentProvider');
    const currrentPaymentProvider =
      localPaymentProvider &&
      localPaymentProvider !== paymentProviderType.STRIPE
        ? localPaymentProvider
        : paymentProviderType.ESEWA;
    setSelectedProvider(currrentPaymentProvider);
    fetchProviders();
  }

  if (isLoading) {
    return <FullPageSpinner />;
  }

  if (isError) {
    return (
      <div className="container mx-auto mb-16">
        <div className="text-sm font-medium text-error mb-5">
          Oops! something went wrong. Please try again in a moment.
        </div>
      </div>
    );
  }
  const contextValue: PaymentProviderContextType = {
    country,
    providers,
    selectedProvider,
    setSelectedProvider
  };
  if (selectedProvider) {
    return <PaymentProviderContext.Provider value={contextValue} {...props} />;
  }

  throw new Error('Unhandled error: PaymentProvider');
}

export function usePaymentProvider() {
  const context = React.useContext(PaymentProviderContext);
  if (context === undefined) {
    throw new Error(`usePaymentProvider must be used within a PaymentProvider`);
  }
  return context;
}
