import React from 'react';

interface Props {
  type?: 'button' | 'submit';
  disabled?: boolean;
  onHandlePay?: () => void;
}

function PayButton({ type = 'button', disabled = false, onHandlePay }: Props) {
  return (
    <>
      <button
        type={type}
        disabled={disabled}
        onClick={() => {
          if (onHandlePay) {
            onHandlePay();
          }
        }}
        className="hidden md:block lg:block flex items-center justify-center text-white bg-primary rounded px-4 py-3 mt-10 hover:bg-opacity-75 focus:outline-none transition ease-in duration-300 w-48"
      >
        <i className="material-icons text-sm pr-2">lock</i>
        <span className="text-sm font-bold pr-1">PAY NOW</span>
      </button>
      <div className="block md:hidden lg:hidden fixed bottom-0 flex items-center justify-center  rounded pt-1 px-6 pb-6 left-0 w-full bg-white">
        <button
          type={type}
          disabled={disabled}
          onClick={() => {
            if (onHandlePay) {
              onHandlePay();
            }
          }}
          className="flex items-center justify-center text-white bg-primary rounded px-4 py-3 hover:bg-opacity-75 focus:outline-none transition ease-in duration-300 w-full"
        >
          <i className="material-icons text-sm pr-2">lock</i>
          <span className="text-sm font-bold pr-1">PAY NOW</span>
        </button>
      </div>
    </>
  );
}

export default PayButton;
